import React from 'react'
import PropTypes from 'prop-types'
import { domToReact } from 'html-react-parser'

const transformAttributes = (attribs) => {
  const transformed = {}

  Object.keys(attribs).forEach((key) => {
    let newKey = key

    if (key === 'class') newKey = 'className'
    if (key === 'for') newKey = 'htmlFor'
    if (key === 'autocomplete') newKey = 'autoComplete'

    transformed[newKey] = attribs[key]
  })

  return transformed
}

const FormField = ({
  node,
  formValues,
  handleInputChange,
  fieldErrors,
  fieldRefs,
  validationRules,
}) => {
  const { name, id, attribs, children } = node
  const reactAttribs = transformAttributes(attribs)

  const commonProps = {
    key: node.key,
    className: reactAttribs.className || '',
    'aria-invalid': fieldErrors[id] ? 'true' : 'false',
    'aria-describedby': fieldErrors[id] ? `${id}-error` : undefined,
    ref: (el) => {
      if (el) {
        fieldRefs.current[id] = el
      }
    },
    ...reactAttribs,
  }

  switch (node.name) {
    case 'input': {
      const { type = 'text', id } = reactAttribs
      const marginBottom = type === 'hidden' ? '0rem' : '1rem'
      return (
        <div key={node.key} style={{ marginBottom }}>
          <input
            {...commonProps}
            type={type}
            name={id}
            id={id}
            value={formValues[id] || ''}
            onChange={handleInputChange}
            readOnly={type === 'hidden'}
          />
          {fieldErrors[id] && (
            <span
              id={`${id}-error`}
              style={{ color: 'red', display: 'block', marginTop: '0.25rem' }}
            >
              {fieldErrors[id]}
            </span>
          )}
        </div>
      )
    }

    case 'select': {
      return (
        <div key={node.key} style={{ marginBottom: '1rem' }}>
          <select
            {...commonProps}
            name={reactAttribs.id}
            id={reactAttribs.id || name}
            value={formValues[id] || ''}
            onChange={handleInputChange}
          >
            {domToReact(children)}
          </select>
          {fieldErrors[id] && (
            <span
              id={`${id}-error`}
              style={{ color: 'red', display: 'block', marginTop: '0.25rem' }}
            >
              {fieldErrors[id]}
            </span>
          )}
        </div>
      )
    }

    case 'textarea': {
      return (
        <div key={node.key} style={{ marginBottom: '1rem' }}>
          <textarea
            {...commonProps}
            name={reactAttribs.id}
            id={reactAttribs.id || name}
            value={formValues[id] || ''}
            onChange={handleInputChange}
          >
            {domToReact(children)}
          </textarea>
          {fieldErrors[id] && (
            <span
              id={`${id}-error`}
              style={{ color: 'red', display: 'block', marginTop: '0.25rem' }}
            >
              {fieldErrors[id]}
            </span>
          )}
        </div>
      )
    }

    case 'label': {
      return (
        <label
          key={node.key}
          {...commonProps}
          htmlFor={reactAttribs.htmlFor || name}
          className={reactAttribs.className || ''}
          style={{ display: 'block', marginBottom: '0.5rem' }}
        >
          {domToReact(children)}
        </label>
      )
    }

    case 'button': {
      const { type = 'button' } = reactAttribs
      return (
        <button
          key={node.key}
          {...commonProps}
          type={type}
          className={reactAttribs.className || ''}
          style={{ marginTop: '1rem' }}
        >
          {domToReact(children)}
        </button>
      )
    }

    default:
      return undefined
  }
}

FormField.propTypes = {
  node: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  fieldRefs: PropTypes.object.isRequired,
  validationRules: PropTypes.object.isRequired,
}

export default React.memo(FormField)
